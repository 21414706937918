<template>
    <HeaderHome/>
    <div class="container-fluid body">
        <div class="container">
            <div class="row body-box">
                <div class="col-md-8">
                    <h1>Ops!</h1>
                    <p class="text-justify">Se você está vendo essa página é por que algo de errado aconteceu. Tente
                        novamente mais tarde!</p>
                   <Atalhos/>
                </div>
                <div class="col-md-4">
                    <FacebookSide/>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    <FooterEnd/>
</template>

<script>
import {defineComponent} from 'vue';
import HeaderHome from '../components/HeaderHome.vue'
import Footer from '../components/Footer.vue'
import FooterEnd from '../components/FooterEnd.vue'
import Atalhos from "../components/Atalhos";
import FacebookSide from '../components/FacebookSide'

export default defineComponent({
    name: 'NotFoundPage',
    components: {
        HeaderHome,
        Footer,
        FooterEnd,
        Atalhos,
        FacebookSide
    }
});
</script>

<style>

</style>
